<template>
	<h1>Manifesto dell'abitare</h1>
	<video
		autoplay
		id="performance"
		ref="videoPlayer"
		controls="controls"
		controlsList="nodownload"
	>
		<source id="surce" src="../assets/videos/Performance_Chiara.mp4" />

		Il tuo browser non supporta questo sito, provare con un brwoser più
		recente.
	</video>
</template>

<script>
	export default {}
</script>

<style scoped>
	@media only screen and (max-width: 980px) {
		#performance {
			margin-top: 10vh;
			max-width: 95vw;
			border: 0px solid transparent;
			border-radius: 6px;
			box-shadow: 0px 20px 25px -10px black;
		}
		h1 {
			margin-top: 1.5em;
			max-width: 90vw;
			position: center;
			margin-left: 4vw;
			font-size: 4em;
			letter-spacing: 9px;
		}
	}
	@media only screen and (min-width: 981px) {
		#performance {
			text-align: center;
			height: 70vh;
			max-width: 95vw;
			border: 0px solid transparent;
			border-radius: 6px;
			box-shadow: 0px 20px 25px -10px black;
		}
	}
	#surce {
		border: 0px solid transparent;
	}
	h1 {
		margin-top: 1em;
		font-size: 4em;
		letter-spacing: 9px;
	}
</style>
