<template>
	<div class="home">
		<player></player>
	</div>
</template>

<script>
	// @ is an alias to /src
	import HelloWorld from '@/components/HelloWorld.vue'
	import Player from '../components/Player.vue'

	export default {
		name: 'Home',
		components: {
			HelloWorld,
			Player,
		},
	}
</script>
