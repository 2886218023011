<template>
	<router-view />
</template>

<style>
	@media only screen and (max-width: 980px) {
		html {
			background: url(./assets/images/sfondoMobile.png) no-repeat center
				center fixed;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
		}
	}
	@media only screen and (min-width: 981px) {
		html {
			background: url(./assets/images/sfondo2.png) no-repeat center center
				fixed;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
		}
	}

	@font-face {
		font-family: 'Halcyon';
		src: local('Halcyon'),
			url(./assets/font/Halcyon_Regular.otf) format('truetype');
	}
	#app {
		font-family: Halcyon, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #0c0f11;
	}

	#nav {
		padding: 30px;
	}

	#nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}
</style>
